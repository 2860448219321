











































import { Component, Vue, Prop } from 'vue-property-decorator';

import Modal from '@/globalComponents/Modal/Modal.vue';

import { getDataSeparatedFromDateTime } from '@/share/Util/Dates';

import { PaymentSuccess } from '../../../Shopping/interfaces/IPayment';

import { METHOD_PAYMENT_NAME } from '../../../Shopping/constants';

@Component({
  components: {
    Modal
  }
})
export default class ModalHistoric extends Vue {
  @Prop() product!: PaymentSuccess;

  methods(method: string) {
    return method ? METHOD_PAYMENT_NAME[method] : 'Não identificado';
  }

  formattedDate(date: string) {
    if (!date) return '';

    const { day, month, year } = getDataSeparatedFromDateTime(date);

    return `${day}/${month}/${year}`;
  }
}
