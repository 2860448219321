




















































import { Vue, Component } from 'vue-property-decorator';

import PageHeader from '@/components/PageHeader/PageHeader.vue';
import ContainerFluid from '@/components/ContainerFluid/ContainerFluid.vue';
import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';
import Footer from '@/components/Footer/Footer.vue';

import PaymentService from '@/services/Payment/PaymentService';

import { PaymentSuccess } from '../Shopping/interfaces/IPayment';

import HistoricTable from './components/HistoricTable/HistoricTable.vue';
import HistoricPaymentLoading from './HistoricPaymentLoading.vue';

const PAYMENT_STATUS_PAID = 'paid';

@Component({
  components: {
    PageHeader,
    ContainerFluid,
    BoxContainer,
    HistoricPaymentLoading,
    HistoricTable,
    FeedbackUser,
    Footer
  }
})
export default class HistoricPayment extends Vue {
  private isLoading = false;
  private isActivePlan = true;

  private payments: PaymentSuccess[] | null = null;

  private PaymentService = new PaymentService();

  async created() {
    this.getPayments();

    this.setBreadCrumbs();
  }

  get isMobile() {
    return this.$responsive.isMobile;
  }

  get plans() {
    if (!this.payments?.length) return [];

    const plans = this.payments.filter((payment: PaymentSuccess) => payment.product.isPlan);

    return plans;
  }

  get products() {
    if (!this.payments?.length) return [];

    const products = this.payments.filter((payment: PaymentSuccess) => !payment.product.isPlan);

    return products;
  }

  async getPayments() {
    try {
      this.isLoading = true;

      const response = await this.PaymentService.getPayment({
        statusExternal: PAYMENT_STATUS_PAID
      });

      if (response.length) this.payments = response;
    } catch (error) {
      console.error(error);

      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar os dados de pagamentos.',
        status: 'error'
      });
    } finally {
      this.isLoading = false;
    }
  }

  handleClickActivePlan() {
    this.isActivePlan = true;
  }

  handleClickActiveProduct() {
    this.isActivePlan = false;
  }

  async setBreadCrumbs() {
    this.$breadcrumb.set([{ title: 'Histórico de compra', to: '/historico' }]);
  }
}
