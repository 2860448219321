



































































import { Vue, Component, Prop } from 'vue-property-decorator';

import FeedbackUser from '@/components/FeedbackUser/index.vue';

import { getDataSeparatedFromDateTime } from '@/share/Util/Dates';
import ActiveModal from '@/share/Util/ActiveModal';

import { PaymentSuccess } from '../../../Shopping/interfaces/IPayment';

import { METHOD_PAYMENT_NAME } from '../../../Shopping/constants';

import { LIST_HEADER_TABLE_HISTORIC } from '../../constants';

import Cart from '@/assets/icons/Cart.vue';

import ModalHistoric from '../ModalHistoric/ModalHistoric.vue';

@Component({
  components: {
    Cart,
    FeedbackUser,
    ModalHistoric
  }
})
export default class HistoricTable extends Vue {
  @Prop() products!: PaymentSuccess[];

  private activeIndexProduct = 0;

  private LIST_HEADER_TABLE_HISTORIC = LIST_HEADER_TABLE_HISTORIC;

  private setModal = new ActiveModal();

  get orderProducts() {
    const newProducts = this.products.sort(
      (productOne: PaymentSuccess, productTwo: PaymentSuccess) => new Date(productTwo.payment.created_date).getTime()
        - new Date(productOne.payment.created_date).getTime()
    );

    return newProducts;
  }

  get selectedProduct() {
    if (!this.orderProducts.length || this.activeIndexProduct === null) return null;

    return this.orderProducts[this.activeIndexProduct];
  }

  methods(method: string) {
    return method ? METHOD_PAYMENT_NAME[method] : 'Não identificado';
  }

  formattedDate(date: string) {
    if (!date) return '';

    const { day, month, year } = getDataSeparatedFromDateTime(date);

    return `${day}/${month}/${year}`;
  }

  handleClickShowModal(indexProduct: number) {
    this.activeIndexProduct = indexProduct;

    this.setModal.activeModal('ModalHistoricPayment');
  }
}
